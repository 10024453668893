import { render, staticRenderFns } from "./Emails.vue?vue&type=template&id=c1338056&scoped=true&"
import script from "./Emails.vue?vue&type=script&lang=ts&"
export * from "./Emails.vue?vue&type=script&lang=ts&"
import style0 from "./Emails.vue?vue&type=style&index=0&id=c1338056&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1338056",
  null
  
)

export default component.exports