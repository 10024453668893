


























































































































































































































import Component, { mixins } from 'vue-class-component'
import moment from 'moment'
import { Email } from '@/edshed-common/api/types/email'
import StringConversions from '@/mixins/StringConversions'
import { Api, HttpResponseError, request, TableState } from '@/edshed-common/api'
import { Watch } from 'vue-property-decorator'
import { sleep } from '@/edshed-common/utils'
import api from '../../api'

@Component({
  components: {
  }
})
export default class EmailsView extends mixins(StringConversions) {
  showAddModal: boolean = false
  draftEmailsData: Email[] = []
  sentEmailsData: Email[] = []
  response: string = ''
  showAll: boolean = false
  loading: boolean = false
  filter: string = 'drafts'
  showPreview: boolean = false
  previewContent: string = ''
  draftTableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'creation_date',
    dir: 'desc',
    term: ''
  }

  sentTableState: TableState = {
    page: 1,
    perPage: 10,
    sort: 'creation_date',
    dir: 'desc',
    term: ''
  }

  draftTotal: number = 0
  sentTotal: number = 0
  sendingEmail: boolean = false
  progress: number = 0
  startedJob: boolean = false
  jobToReport: string | null = null
  @Watch('jobToReport')
  async checkSendJob (val: string | null) {
    if (val === null) {
      return
    }

    try {
      const queueId = 'sendEmailQueue'
      const jobId = val
      const progress = await Api.checkProcessingJob(queueId, jobId)

      this.sendingEmail = true

      if (!this.startedJob) {
        this.progress = progress
        await sleep(1000)
        this.startedJob = true
      } else {
        this.tweenProgress(this.progress, progress, 2000, 50)
        await sleep(2000)
      }
      this.checkSendJob(val)
    } catch (err: unknown) {
      if (err instanceof HttpResponseError && err.status === 404) {
        // job is complete
        if (this.tweeningFunc || this.startedJob) {
          this.tweenProgress(this.progress, 100, 1000, 10)
          await sleep(1000)
          this.$buefy.toast.open({
            duration: 3000,
            message: 'Finished sending email',
            position: 'is-bottom',
            type: 'is-success'
          })
        }
        this.getDraftEmails()
        this.getSentEmails()
        await sleep(100)
        this.sendingEmail = false
        await sleep(300)
        this.progress = 0
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'Could not check email status',
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  }

  tweeningFunc: NodeJS.Timeout | null = null

  tweenProgress (from: number, to: number, period: number, steps: number) {
    const that = this
    this.progress = from

    if (this.tweeningFunc) {
      clearTimeout(this.tweeningFunc)
    }

    const stepLength = period / steps
    const stepSize = (to - from) / steps

    function doTween () {
      that.tweeningFunc = setTimeout(() => {
        that.progress += stepSize

        if (that.progress < to) {
          doTween()
        }
      }, stepLength)
    }

    doTween()
  }

  get isAdmin () {
    return (this.$store.state.user.school.admin === 1)
  }

  get formattedEmailHTML () {
    let html = encodeURI(this.previewContent)
    html = html.replace(/#/g, '%23')
    return html
  }

  mounted () {
    this.$nextTick(() => {
      this.getDraftEmails()
      this.getSentEmails()
      this.jobToReport = typeof this.$route.query.job === 'string' ? this.$route.query.job : null
    })
  }

  setFilter (value) {
    this.filter = value
  }

  getDraftEmails () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    api.getPaged('emails', this.draftTableState, { status: '0' })
      .then((response) => {
        const data = response.items
        this.draftEmailsData = response.items
        this.draftTotal = response.total
        if (!this.draftEmailsData || this.draftEmailsData.length === 0) {
          this.response = 'No Emails'
        }
        // console.log('readerData: ' + this.readerData)
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  getSentEmails () {
    this.toggleLoading()
    this.$store.commit('TOGGLE_LOADING')
    api.getPaged('emails', this.sentTableState, { status: '1' })
      .then((response) => {
        const data = response.items
        this.sentEmailsData = response.items
        this.sentTotal = response.total
        if (!this.sentEmailsData || this.sentEmailsData.length === 0) {
          this.response = 'No Emails'
        }
        // console.log('readerData: ' + this.readerData)
        this.toggleLoading()
        this.$store.commit('TOGGLE_LOADING')

        this.updateEmailStats()
      })
      .catch((error) => {
        console.log(error)
        this.$store.commit('TOGGLE_LOADING')
        this.toggleLoading()
        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }
        this.response = 'Details incorrect'
      })
  }

  async updateEmailStats () {
    try {
      const stats = await Api.getEmailStats(this.sentEmailsData.map(e => e.id))

      for (const id of Object.keys(stats)) {
        const stat = stats[parseInt(id)]

        const email = this.sentEmailsData.find(e => e.id === parseInt(id))

        if (email) {
          email.clicked_count = stat.clicked
          email.opened_count = stat.opened
          email.delivered_count = stat.delivered
        }
      }
    } catch (err) {
      this.$buefy.toast.open({
        message: 'Could not update email stats',
        position: 'is-bottom',
        type: 'is-warning'
      })
    }
  }

  previewEmail (id) {
    request('GET', 'email/' + id + '/preview', null)
      .then((response) => {
        this.previewContent = response.data.data
        this.showPreview = true
      })
      .catch((error) => {
        console.log(error)

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  forwardEmail (id) {
    request('GET', 'email/' + id + '/forward', null)
      .then((response) => {
        // redirect to new path
        this.$router.push('/emails/' + response.data.email.id)
      })
      .catch((error) => {
        console.log(error)

        if (error.response.status === 403) {
          console.log('FORBIDDEN')
          this.$router.push('/logout')
        }

        this.response = 'Details incorrect'
      })
  }

  deleteEmail (id) {
    const del = confirm('Are you sure you want to delete this Email? This action cannot be undone.')
    if (del) {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('DELETE', 'email/' + id, null)
        .then((response) => {
          if (this.filter === 'drafts') {
            this.getDraftEmails()
          } else if (this.filter === 'sent') {
            this.getSentEmails()
          }

          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    }
  }

  toggleLoading () {
    this.loading = !this.loading
  }

  dateFromServerDate (dt) {
    if (dt === null || dt === '' || !moment(dt).isValid()) {
      return null
    }
    const m = moment(dt).local()
    const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
    return dts
  }

  dateInRange (start, end) {
    let startDate: moment.Moment | null = null
    if (start) {
      startDate = moment(start)
    }
    const now = moment()

    let expiry: moment.Moment | null = null
    if (end) {
      expiry = moment(end)
    }
    if (start && startDate! > now) {
      return 'default'
    } else if (end && expiry! < now) {
      return 'danger'
    } else {
      return 'success'
    }
  }

  getStatusFromFilterName (filter) {
    switch (filter) {
      case 'drafts':
        return 0
      case 'sent':
        return 1
      default:
        return -1
    }
  }

  sortChanged (field, dir) {
    if (this.filter === 'drafts') {
      this.draftTableState.sort = field
      this.draftTableState.dir = dir

      this.getDraftEmails()
    } else if (this.filter === 'sent') {
      this.sentTableState.sort = field
      this.sentTableState.dir = dir

      this.getSentEmails()
    }
  }

  pageChanged (page) {
    if (this.filter === 'drafts') {
      this.draftTableState.page = page

      this.getDraftEmails()
    } else if (this.filter === 'sent') {
      this.sentTableState.page = page

      this.getSentEmails()
    }
  }
}
